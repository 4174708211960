<template>
    <div class="page page-bg">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :left-text="$t('修改登录密码')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper-bg">
            <van-form class="bs-form login-form">
                <van-field v-model="old_password" :placeholder="$t('请输入原密码')" />
                <van-field v-model="new_password" :placeholder="$t('请输入新密码')" />
                <van-field v-model="q_password" :placeholder="$t('请输入确认新密码')" />
            </van-form>
        </div>
        <div class="btn-foot-space">
            <van-button  block color="linear-gradient(to right, #3270e0, #3653ea)" @click="updatePwd">{{$t('确认')}}</van-button>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                show: false,
                old_password: '',
                new_password: '',
                q_password: '',
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            updatePwd() {
				if(this.old_password == ''){
					this.$toast.fail(this.$t('请输入原始密码'));
					return;
				}
                if (this.new_password != this.q_password) {
                    this.$toast.fail(this.$t('两次密码不一致'));
                    return;
                }
                this.$axios.post(this.$api_url.update_pwd,
                    {old_password: this.old_password, new_password: this.new_password}
                ).then((res) => {
                    this.$toast.success(res.data.msg);
                    if(res.data.code == 0){
						this.$helper.forgetToken();
						location.href = "/#/login";
					}
                }).catch((e) => {
                    this.$toast.fail(e.data.msg);
                })
            }
        }

    }
</script>
<style>
.bs-form .van-field__label{
    line-height: 30px;
}
</style>